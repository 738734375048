//
// Poll Editor
// --------------------------------------------------

.poll-choices-control {
  .list-group-item {
    padding: 0px;
  }

  .list-group-item .btn {
    background: transparent;
    border: transparent;
    float: left;
    margin: 0px 2px;
    margin-bottom: -29px;
    padding: 0px;
    width: 28px;
    height: 28px;

    position: relative;
    top: 3px;

    .material-icon {
      width: 28px;
      height: 28px;

      font-size: 28px;
      line-height: 28px;
    }
  }

  input {
    &,
    &:focus,
    &:active {
      background: transparent;
      border: none;
      .box-shadow(none);
      outline: none;
      margin-left: 30px;
      padding: @padding-base-vertical @padding-base-horizontal;
      width: 100%;
    }
  }
}
