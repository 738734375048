//
// Page Header
// --------------------------------------------------

// Remove margins from header
.page-header {
  margin: 0;
  margin-top: floor(@line-height-computed * 0.5);
}

// Add padding to header's banner and message
.page-header-banner-bg-overlay {
  padding: @line-height-computed 0;
}

// Don't rely on header's margins for spacing, force word break
.page-header h1 {
  margin: 0;

  overflow-wrap: break-word;
}
