//
// Posts Flavor
// --------------------------------------------------

// Post body
.panel-post {
  background: @post-bg;
  border: 1px solid @post-border;
}

// Post side
.post-side {
  color: @gray-light;

  .poster-avatar {
    border-radius: @border-radius-base;
  }

  .user-title {
    color: @gray;

    a,
    a:link,
    a:visited,
    a:focus,
    a:hover,
    a:active {
      color: @gray;
    }
  }
}

// Header
.post-heading {
  .label-unread {
    background-color: @post-unread-label;
    margin-right: @padding-large-horizontal;
  }

  .label-protected {
    background-color: transparent;
    margin-left: @padding-base-horizontal * 2;

    position: relative;
    top: 1px;

    color: @post-protected-icon;

    .material-icon {
      margin-right: 2px;

      position: relative;
      top: -1px;

      font-size: 16px;
      line-height: 16px;
    }
  }

  & > .btn-link {
    padding-left: 0px;
    padding-right: 0px;

    &,
    &:link,
    &:visited {
      color: @gray-light;
    }

    &:hover,
    &:focus,
    &:focus:active,
    &:active {
      color: @text-color;
      text-decoration: none;
    }
  }

  .btn-see-edits {
    margin-left: @padding-base-horizontal * 2;
  }
}

// Status
.post-status-message {
  border-radius: @border-radius-base;
  margin-top: @line-height-computed / 2;
  padding: @padding-base-vertical @padding-base-horizontal;

  @media screen and (max-width: @screen-xs-max) {
    font-size: @font-size-small;

    .material-icon {
      margin-top: 3px;
    }

    p {
      margin-top: 0px;
    }
  }
}

// Post body
.post-body:last-child {
  padding-bottom: @line-height-computed / 2;
}

// Attachments
.post-attachments {
  background-color: @gray-lighter;
  border: none;
  border-radius: @border-radius-base;
  margin-bottom: @line-height-computed * 1.5;

  &:last-child {
    margin-bottom: @line-height-computed / 2;
  }

  @media screen and (max-width: @screen-xs-max) {
    border-radius: 0px;
    margin: 0px -15px;
    margin-bottom: @line-height-computed;
  }
}

// Footer
.post-footer {
  & > .btn-link {
    padding-left: 0px;
    padding-right: 0px;

    &,
    &:link,
    &:visited {
      color: @gray-light;
    }

    &:hover,
    &:focus,
    &:focus:active,
    &:active {
      color: @text-color;
      text-decoration: none;
    }
  }

  p {
    color: @gray-light;
    font-size: @font-size-small;
  }
}

// Hidden and invalid messages
.post-body-hidden,
.post-body-invalid {
  padding-top: @line-height-computed / 2;
  padding-bottom: @line-height-computed / 2;

  .lead {
    margin-bottom: @line-height-computed / 2;
  }

  .text-muted {
    margin-bottom: 0px;

    font-size: @font-size-small;
  }
}

// Fade out hidden posts a little
.post-hidden {
  .opacity(0.75);
}
