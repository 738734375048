/*
Description: Foundation 4 docs style for highlight.js
Author: Dan Allen <dan.j.allen@gmail.com>
Website: http://foundation.zurb.com/docs/
Version: 1.0
Date: 2013-04-02
*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #eee; color: black;
}

.hljs-link,
.hljs-emphasis,
.hljs-attribute,
.hljs-addition {
  color: #070;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong,
.hljs-string,
.hljs-deletion {
  color: #d14;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-quote,
.hljs-comment {
  color: #998;
  font-style: italic;
}

.hljs-section,
.hljs-title {
  color: #900;
}

.hljs-class .hljs-title,
.hljs-type {
  color: #458;
}

.hljs-variable,
.hljs-template-variable {
  color: #336699;
}

.hljs-bullet {
  color: #997700;
}

.hljs-meta {
  color: #3344bb;
}

.hljs-code,
.hljs-number,
.hljs-literal,
.hljs-keyword,
.hljs-selector-tag {
  color: #099;
}

.hljs-regexp {
  background-color: #fff0ff;
  color: #880088;
}

.hljs-symbol {
  color: #990073;
}

.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #007700;
}
