//
// Posting
// --------------------------------------------------

// Affix and style placeholder
#posting-placeholder {
  background-color: @gray-lighter;
  display: none;
  margin-top: @line-height-computed * 1.5;
  margin-bottom: @line-height-computed * -1.5;
  padding: @line-height-computed 0px;

  transition: height 300ms;

  &.slide-in {
    display: block;
  }
}

// First row
#posting-placeholder .first-row {
  margin-bottom: @line-height-computed;

  .form-control {
    border: 1px solid darken(@gray-lighter, 15%);
  }

  .posting-options {
    .btn {
      padding-top: 4px;
      padding-bottom: 4px;

      .btn-text {
        margin-left: @line-height-computed / 4;

        position: relative;
        top: 1px;
      }
    }

    .material-icon {
      width: 14px;
      height: 24px;

      margin-right: 0px;

      position: relative;
      top: 5px;

      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
}

// Posting ui preview
.posting-ui-preview {
  padding: @line-height-computed 0px;
  position: relative;

  .form-control {
    box-shadow: none;
    resize: none;
  }
}

// Posting Loader
.posting-loader {
  text-align: center;
}

.posting-loader .loader {
  height: 100px;

  .loader-spinning-wheel {
    width: 100px;
    height: 100px;
  }
}

// Posting message
@posting-icon-width: 28px;

.posting-message {
  text-align: center;

  .material-icon {
    margin-right: @line-height-computed / 3;
    position: relative;
    top: -1px;

    width: @posting-icon-width;
    height: @posting-icon-width;

    font-size: @posting-icon-width;
    line-height: @posting-icon-width;
  }

  .message-body {
    p {
      font-size: @font-size-large;
    }
  }
}
